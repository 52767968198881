<template>
<b-row class="my-5">
  <b-col cols="12" class="d-flex justify-content-center">
    <b-dropdown size="sm" ref="dropdown" variant="link" class="btn btn-outline-primary mr-3" id="filter" no-caret no-flip>
      <template #button-content>
        <span id="filter">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.239353 1.81125C2.52425 4.725 6.74338 10.125 6.74338 10.125V16.875C6.74338 17.4937 7.25239 18 7.87452 18H10.1368C10.7589 18 11.2679 17.4937 11.2679 16.875V10.125C11.2679 10.125 15.4758 4.725 17.7606 1.81125C18.3375 1.06875 17.8059 0 16.8671 0H1.13295C0.194107 0 -0.337527 1.06875 0.239353 1.81125Z" fill="#35C0C0"/></svg>
           <b-tooltip target="filter">{{ $t('search.filter') }}</b-tooltip>
        </span>
        <svg class="car" style="margin-top: 3px; margin-left: 5px;" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1L6 6L1 1" stroke="#35C0C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <span v-if="filterCount > 0" class="ml-3">
          <b-badge variant="light" pill>{{ filterCount }}</b-badge><BIconX @click="resetFilters" variant="dark"/>
        </span>
      </template>

      <b-dropdown-group id="filterByCategory" class="filterGroup" :header="$t('general.categories')">
        <b-dropdown-form>
          <b-form-checkbox v-model="selectedCategoryAll" @change="allCategories">{{ $t("tags.all") }}</b-form-checkbox>
          <div v-for="cat in mediaCategories" :key="cat.id" >
            <span v-for="n in cat.names" :key="n.id">
              <template v-if="n.language.shortName == $root.lang.shortName">
                <b-form-checkbox v-model="selectedCategory" :value="cat.id" @change="searchMedia">{{ n.name }}</b-form-checkbox>
              </template>
            </span>
          </div>
        </b-dropdown-form>
      </b-dropdown-group>

      <b-dropdown-divider class="filterDivider" v-if="type == 'video' || type=='audio'"></b-dropdown-divider>

      <b-dropdown-group id="filterByLanguage" class="filterGroup" :header="$t('videos.filterByLanguage')" v-if="type == 'video' || type=='audio'">
        <b-dropdown-form>
          <b-form-checkbox v-model="selectedLanguageAll" @change="selectAllLanguages">{{ $t("language.allLanguages") }}</b-form-checkbox>
          <b-form-checkbox v-for="ml in mediaLanguages" :key="ml" v-model="selectedLanguage" :value="ml" @change="searchMedia">{{ ml }}</b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown-group>

      <b-dropdown-divider class="filterDivider"></b-dropdown-divider>

      <b-dropdown-group id="filterByYear" class="filterGroup" :header="$t('videos.filterByYear')">
        <b-dropdown-form>
          <b-form-checkbox v-model="selectedYearAll" @change="selectAllYears">{{ $t("general.allYears") }}</b-form-checkbox>
          <b-form-checkbox v-for="y in mediaYears" :key="y" v-model="selectedYear" :value="y" @change="searchMedia">{{ y }}</b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown-group>

      <b-dropdown-divider class="filterDivider"></b-dropdown-divider>

      <b-dropdown-group id="sort" :header="$t('search.sort')">
        <b-dropdown-form>
          <b-form-checkbox v-model="sort" value="createdAt,desc" switch @change="searchMedia">{{$t('search.newest-first')}}</b-form-checkbox>
          <b-form-checkbox v-model="sort" value="createdAt,asc" switch @change="searchMedia">{{$t('search.oldest-first')}}</b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown-group>

    </b-dropdown>

    <b-form-input class="searchBar" @input="searchMedia" v-model="search" size="lg" trim :placeholder="$t('search.search')" autocomplete="new-password"></b-form-input>
  </b-col>
</b-row>
</template>

<script>
import { BFormCheckbox, BBadge, BIconX, BTooltip } from 'bootstrap-vue'
export default {
  name: 'SearchAndFilterPublic',
  components: { BFormCheckbox, BBadge, BIconX, BTooltip  },
  props: {
    type: String
  },

  data: function() {
    return {
      sort: "createdAt,desc",
      search: "",

      mediaCategories: [],
      selectedCategory: [],
      selectedCategoryAll: true,

      mediaLanguages: ['Serbian', 'English', 'German', 'Slovak', 'Hungarian', 'Bulgarian', 'Romanian', 'Ukranian', 'Moldovan', 'Rusyn', 'Roma', "Other"],
      selectedLanguage: [],
      selectedLanguageAll: true,
      
      mediaYears: [],
      selectedYear: [],
      selectedYearAll: true

    }
  },
  created: async function() {
    
    await this.$http.get('/api/tag/all').then(r => {
      this.mediaCategories = r.data;
    }).catch(e => {
      var message = this.handleError(e);
    })

    await this.$http.get("/api/media/years/dist/" + this.type).then((r) => {
      this.mediaYears = r.data;
    }).catch((e) => { console.log(e) });
  },

  computed: {
    filterCount: function() {
        return this.selectedLanguage.filter(Boolean).length + this.selectedCategory.filter(Boolean).length + this.selectedYear.filter(Boolean).length;
    }
  },

  methods: {
    allCategories: function() {
      // console.log(this.selectedCategoryAll + ":" + this.selectedCategory.length);
      if (this.selectedCategoryAll) {
        this.selectedCategory = [];
      }
      this.searchMedia();
    },

    selectAllLanguages: function() {
      if (this.selectedLanguageAll) {
        this.selectedLanguage = [];
      }
      this.searchMedia();
    },

    selectAllYears: function() {
      if (this.selectedYearAll) {
        this.selectedYear = [];
      }
      this.searchMedia();
    },

    searchMedia: function() {
      // Ako je sort false
      if (!this.sort) {
        this.sort = "createdAt,desc";
      }
      // Ako se selektuje kategorija, dečekira se opcija Sve kategorije
      if (this.selectedCategory.length > 0) {
        this.selectedCategoryAll = false;
      } else {
        this.selectedCategoryAll = true;
      }
 
      if (this.selectedLanguage.length > 0) {
        this.selectedLanguageAll = false;
      } else {
        this.selectedLanguageAll = true;
      }
      
      if (this.selectedYear.length > 0) {
        this.selectedYearAll = false;
      } else {
        this.selectedYearAll = true;
      }

      this.$emit('searchVideo', 
        { 
          language : this.selectedLanguage.filter(Boolean), 
          keyword: this.search, 
          type: this.type,
          category: this.selectedCategory.filter(Boolean),
          displayLang: this.$root.lang.IANA,
          year: this.selectedYear
        }, this.sort);
    },

    resetFilters: function(e) {
      this.selectedCategory = [];
      this.selectedCategoryAll = true;
      this.selectedLanguage = [];
      this.selectedLanguageAll = true;
      this.selectedYear = [];
      this.selectedYearAll = true;
    }

  }
}
</script>
<style></style>
